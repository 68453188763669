/* QRRedirect specific styles */
.qr-redirect-content {
  background-color: #282c34;
}

.redirect-typewriter h2 {
  overflow: hidden;
  border-left: .15em solid white;
  white-space: nowrap;
  margin: 0 auto;
  padding-top: 10px;
  font-family: courier;
  font-size: 0.8em;
  direction: rtl;
  animation: 
    typewriter 2s steps(40, end),
    blinkTextCursor 500ms steps(40, end) infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.redirect-spinner {
  animation: spin 1s linear infinite;
  margin-bottom: 20px !important;
}

/* Countdown animation */
.countdown {
  font-size: 3em;
  font-weight: bold;
  color: #ed964b;
  margin: 20px 0;
  font-family: "Roboto Mono";
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from { opacity: 0.7; transform: scale(1); }
  to { opacity: 1; transform: scale(1.1); }
}

/* Button spacing */
.button {
  margin: 10px;
} 